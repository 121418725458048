/* GLITCH  */

.glitch {
    text-decoration: none;
    text-transform: uppercase;
    position: absolute;
    margin: 0;
    color: #fff;
    letter-spacing: 5px;
    animation-delay: 5s;
}
.glitch:before,
.glitch:after {
    display: block;
    content: attr(data-glitch);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.8;
}
.glitch:after {
    color: #f0f;
    z-index: -2;
}
.glitch:before {
    color: #0ff;
    z-index: -1;
}
.glitch:hover:before {
    animation: glitch 0.09s cubic-bezier(0.25, 0.46, 0.45, 0.94) both 3;
}
.glitch:hover:after {
    animation: glitch 0.09s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both 4;
}

@keyframes glitch {
    0% {
        transform: translate(0);
    }
    20% {
        transform: translate(-4px, 0px);
    }
    40% {
        transform: translate(-4px, 0px);
    }
    60% {
        transform: translate(4px, 0px);
    }
    80% {
        transform: translate(4px, 0px);
    }
    to {
        transform: translate(0);
    }
}

/* WATERMELON
 */

.gradient-text {
    background: linear-gradient(to right, crimson, pink, springgreen);
    background-size: 200% 200%;
    animation: rainbow 2s ease-in-out infinite;
    background-clip: text;
    -webkit-background-clip: text;
    color: rgba(0, 0, 0, 1);
    font-size: 10rem;
    text-align: center;
    transition: color 0.2s ease-in-out;
    text-transform: uppercase;
    font-weight: 900;
}
.gradient-text:hover {
    color: rgba(0, 0, 0, 0);
}
@keyframes rainbow {
    0% {
        background-position: left;
    }
    50% {
        background-position: right;
    }
    100% {
        background-position: left;
    }
}

/* GLITCH-2  */

.glitch-2 {
    text-decoration: none;
    text-transform: uppercase;
    position: absolute;
    margin: 0;
    color: #fff;
    letter-spacing: 5px;
    animation-delay: 5s;
}
.glitch-2:before,
.glitch-2:after {
    display: block;
    content: attr(data-glitch-2);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0.8;
}
.glitch-2:after {
    color: #f0f;
    z-index: -2;
}
.glitch-2:before {
    color: #0ff;
    z-index: -1;
}
.glitch-2:before {
    animation: glitch-2 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both infinite;
}
.glitch-2:after {
    animation: glitch-2 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both
        infinite;
}

@keyframes glitch-2 {
    0% {
        transform: translate(0);
    }
    20% {
        transform: translate(-2px, 0px);
    }
    40% {
        transform: translate(-2px, 0px);
    }
    60% {
        transform: translate(2px, 0px);
    }
    80% {
        transform: translate(2px, 0px);
    }
    to {
        transform: translate(0);
    }
}
