body {
    background-color: black;
    color: white;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@font-face {
    font-family: 'Average-Regular';
    src: local('Average-Regular'),
        url(./fonts/Average-Regular.woff) format('opentype');
}

@font-face {
    font-family: 'Antonio-ExtraLight';
    src: local('Antonio-ExtraLight'),
        url(./fonts/Antonio-ExtraLight.woff) format('opentype');
}

@font-face {
    font-family: 'Antonio-Light';
    src: local('Antonio-Light'),
        url(./fonts/Antonio-Light.woff) format('opentype');
}

@font-face {
    font-family: 'Antonio-Medium';
    src: local('Antonio-Medium'),
        url(./fonts/Antonio-Medium.woff) format('opentype');
}

@font-face {
    font-family: 'Antonio-Regular';
    src: local('Antonio-Regular'),
        url(./fonts/Antonio-Regular.woff) format('opentype');
}

@font-face {
    font-family: 'Antonio-SemiBold';
    src: local('Antonio-SemiBold'),
        url(./fonts/Antonio-SemiBold.woff) format('opentype');
}

@font-face {
    font-family: 'Antonio-Thin';
    src: local('Antonio-Thin'),
        url(./fonts/Antonio-Thin.woff) format('opentype');
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
